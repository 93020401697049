import { useStore } from "@nanostores/react"

import { $searchDialog } from "@stores/searchDialog"
import type { LayoutDef } from "@types"
import Dialog from "@components/Dialog"
import Search from "./Search"

const SearchDialog = (props: { layout: LayoutDef; href: string }): JSX.Element => {
  const { is_open } = useStore($searchDialog)

  return (
    <Dialog
      is_open={is_open}
      onClose={() => $searchDialog.setKey("is_open", false)}
      id="recipe-search-dialog"
      data-cy="search-dialog"
      with_overlay
    >
      <div className="min-h-screen bg-white relative md:fixed md:top-0 md:left-0 md:w-full md:min-h-0 md:pt-96 search-dialog">
        <div className="container md:h-full">
          <div className="relative md:h-full">
            <div className="m-auto max-w-[64rem] md:h-full">
              <Search
                href={props.href}
                onClose={() => $searchDialog.setKey("is_open", false)}
                layout={props.layout}
                is_open={is_open}
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  )
}

export default SearchDialog
