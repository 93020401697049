import cc from "classnames"

import type { CategoryGroupDef } from "@types"
import Link from "@components/Link"
import CollectionWithRefresh from "../CollectionWithRefresh"
import useSWR from "swr"
import fetcher from "@utils/fetcher"

interface SearchEmptyResultsProps {
  categories: CategoryGroupDef[]
  onClose: () => void
}

const CategoryGroup = (props: { category: CategoryGroupDef; onClose: () => void }): JSX.Element => {
  return (
    <div className="pr-16">
      <h2 className="text-18 font-semibold mb-32">{props.category.collection_title}</h2>
      <ul className="leading-none">
        {props.category.collections.map((collection, index) => (
          <li className="mb-16" key={`${index}-${collection.id}`}>
            <Link collection={collection} class_name="py-1 block" onClick={props.onClose}>
              {collection.name}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  )
}

const SearchEmptyResults = (props: SearchEmptyResultsProps): JSX.Element => {
  const { categories } = props

  // TODO this is reused on HeaderDesktop, we should consider adding these values to globals
  const headerCollection = useSWR("/v1/random/inspiration/2621", fetcher, { revalidateOnFocus: false })?.data

  return (
    <div className="mt-16 -mx-16 md:-mx-0" data-cy="empty-state">
      <div className="flex px-16 md:px-0">
        {categories &&
          categories.slice(0, 2).map((category, index) => (
            <div
              className={cc("flex-1", { "hidden md:block": index > 0 })}
              key={`${category.collection_title}-${index}`}
            >
              <CategoryGroup category={category} onClose={props.onClose} />
            </div>
          ))}
      </div>
      <div className="flex md:hidden px-16 mt-18">
        <CollectionWithRefresh
          collection={headerCollection?.collection}
          recipes={headerCollection?.recipes}
          recipes_shown={2}
        />
      </div>
    </div>
  )
}

export default SearchEmptyResults
