import { type ReactNode, useRef, useState } from "react"
import { instantMeiliSearch } from "@meilisearch/instant-meilisearch"
import { InstantSearch, Index, Configure, connectStateResults } from "react-instantsearch-dom"
import type { StateResultsProvided } from "react-instantsearch-core"
import { useTranslation } from "react-i18next"

import type { LayoutDef } from "@types"
import { $searchDialog } from "@stores/searchDialog"
import { ReactComponent as IconClose } from "@icons/close.svg"
import SearchCustomSearchBox from "@components/SearchCustomSearchBox"
import Link from "@components/Link"
import { ReactComponent as ReturnArrow } from "@icons/return-arrow.svg"

import SearchCustomHit from "./SearchCustomHit"
import SearchEmptyResults from "./SearchEmptyResults"

const MultiIndex = (props: { children?: ReactNode }): JSX.Element => {
  return (
    <Index indexName="frontend_multi_index_aggregator">
      <Configure />
      {props.children}
    </Index>
  )
}
interface SearchProps {
  layout: LayoutDef
  onClose: () => void
  is_open: boolean
  href: string
}

const searchClient = instantMeiliSearch(
  import.meta.env.PUBLIC_MEILISEARCH_HOST,
  import.meta.env.PUBLIC_MEILISEARCH_SEARCH_ONLY_API_KEY
)

interface ResultsWithChildren extends StateResultsProvided {
  children: any
}

const Search = (props: SearchProps): JSX.Element => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { layout, is_open, href } = props
  const ref = useRef(null)
  const { t } = useTranslation<string>()
  const [value, setValue] = useState<string>((new URL(href).searchParams.get("query") as string) || "")

  const IndexResults = connectStateResults<ResultsWithChildren>(({ searchResults, isSearchStalled, children }) => {
    if (isSearchStalled) return null
    return searchResults && searchResults.nbHits !== 0 ? children : null
  })

  const AllResults = connectStateResults<ResultsWithChildren>(({ allSearchResults, children }) => {
    // only used when querying multiple indexes at once
    // const hasResults =
    //   allSearchResults && Object.values(allSearchResults).some((results) => (results as any).nbHits > 0)
    const hasResults = allSearchResults?.nbHits > 0

    return hasResults ? (
      children
    ) : (
      <div>
        <div className="mt-32">{t("common:search.noResultsWithoutKeyword")}</div>
        <MultiIndex />
      </div>
    )
  })

  const handleSubmit = () => {
    $searchDialog.setKey("is_open", false)
    location.href = `/r?query=${value}`
  }

  return (
    <InstantSearch indexName="frontend_multi_index_aggregator" searchClient={searchClient}>
      <div
        className="flex items-center w-full relative bg-white pt-64 md:pt-0 md:static md:flex-col md:h-full"
        ref={ref}
      >
        <div className="w-full bg-white fixed top-0 left-0 z-10 p-16 md:static md:p-0">
          <div className="flex">
            <div className="relative w-full">
              <form
                noValidate
                action=""
                role="search"
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit()
                }}
              >
                <SearchCustomSearchBox
                  label=""
                  value={value}
                  setValue={setValue}
                  is_open={is_open}
                  data-cy="search-dialog-input"
                  show_remove_icon
                />
              </form>
            </div>
            <button
              className="flex w-40 h-40 rounded-full bg-buttermilk-100 ml-8 md:absolute md:right-0"
              onClick={props.onClose}
            >
              <div className="h-24 w-24 m-auto">
                <IconClose />
              </div>
            </button>
          </div>
        </div>
        <div
          data-cy="search-overlay"
          className="pt-16 md:pt-48 min-h-search-header w-full md:h-full md:max-h-[60rem] md:overflow-y-scroll md:min-h-0 pb-240 md:pb-48"
        >
          {value !== "" && (
            <Link
              href="/r"
              query={{ query: value }}
              class_name="flex items-center group"
              onClick={props.onClose}
              data-cy="search-link"
            >
              <div className="rounded-4 bg-buttermilk-100 flex items-center justify-center w-32 h-32 group-hover:bg-buttermilk-200 transition-colors duration-300">
                <div className="w-16 text-neutral-300">
                  <ReturnArrow />
                </div>
              </div>
              <div className="ml-16">“{value}”</div>
            </Link>
          )}
          {value !== "" ? (
            <AllResults>
              <MultiIndex>
                <IndexResults>
                  <Configure distinct={3} />
                  <SearchCustomHit />
                </IndexResults>
              </MultiIndex>
            </AllResults>
          ) : (
            <SearchEmptyResults categories={layout?.collection_groups} onClose={props.onClose} />
          )}
        </div>
      </div>
    </InstantSearch>
  )
}

export default Search
