import { useTranslation } from "react-i18next"
import { connectHits } from "react-instantsearch-dom"
import type { HitsProvided } from "react-instantsearch-core"
import groupBy from "lodash/groupBy"
import cc from "classnames"

import { $searchDialog } from "@stores/searchDialog"
import Link, { type LinkProps } from "@components/Link"
import Image from "@components/Image"
import ProfilePicturePlaceholder from "@components/ProfilePicturePlaceholder"

interface SuggestionResultProps {
  hit: any
  link_props: LinkProps
}

const SuggestionResult = (props: SuggestionResultProps): JSX.Element => {
  const { hit, link_props } = props
  const entityType = hit.algolia_type
  const usesProfilePicture = entityType === "user" || entityType === "brand"

  return (
    <Link
      {...link_props}
      class_name="mb-16 flex items-center text-left md:mb-24"
      key={`${hit.objectID}-${entityType}`}
      onClick={() => $searchDialog.setKey("is_open", false)}
    >
      <>
        <div
          className={cc("w-32 overflow-hidden rounded-4", {
            "h-48": !usesProfilePicture,
            "h-32": usesProfilePicture,
          })}
        >
          {hit.media && (
            <Image
              src={hit.media?.permalink}
              alt={hit.media?.alt || hit?.user_name}
              width={32}
              height={usesProfilePicture ? 32 : 48}
              className="fixed h-full object-cover"
            />
          )}
          {usesProfilePicture && !hit.media?.permalink && (
            <ProfilePicturePlaceholder width={32} height={32} font_size="text-base" user={hit} />
          )}
        </div>
        <div className="ml-12 text-14 leading-[120%]">{entityType === "user" ? `${hit?.user_name}` : hit.name}</div>
      </>
    </Link>
  )
}

type HitsProps = HitsProvided<SuggestionResultProps> & {
  entityType?: string
}

const Hits = ({ hits }: { hits: any }): JSX.Element => {
  const { t } = useTranslation<string>()

  // group hits by algolia_type
  const groupedHits = groupBy(hits, "algolia_type")

  return (
    <div>
      {groupedHits?.recipe && groupedHits?.recipe?.length && (
        <div data-cy="recipes-suggestions">
          <div className="my-24 border-t border-neutral-200 pt-12 text-12 text-neutral-300">
            {t("common:common.recipes")}
          </div>
          {groupedHits.recipe.map((hit, index) => {
            return <SuggestionResult hit={hit} link_props={{ href: hit.route }} key={`${hit.objectId}-${index}`} />
          })}
        </div>
      )}
      {groupedHits?.user && groupedHits?.user?.length && (
        <div data-cy="user-suggestions">
          <div className="my-24 border-t border-neutral-200 pt-12 text-12 text-neutral-300">
            {t("common:common.users")}
          </div>
          {groupedHits.user.map((hit, index) => {
            return <SuggestionResult hit={hit} link_props={{ href: hit.route }} key={`${hit.objectId}-${index}`} />
          })}
        </div>
      )}

      {groupedHits?.bundle && groupedHits?.bundle?.length && (
        <div data-cy="bundles-suggestions">
          <div className="my-24 border-t border-neutral-200 pt-12 text-12 text-neutral-300">
            {t("common:common.bundles")}
          </div>
          {groupedHits.bundle.map((hit, index) => {
            return <SuggestionResult hit={hit} link_props={{ href: hit.route }} key={`${hit.objectId}-${index}`} />
          })}
        </div>
      )}

      {groupedHits?.collection && groupedHits?.collection?.length && (
        <div data-cy="collections-suggestions">
          <div className="my-24 border-t border-neutral-200 pt-12 text-12 text-neutral-300">
            {t("common:common.collections")}
          </div>
          {groupedHits.collection.map((hit, index) => {
            return <SuggestionResult hit={hit} link_props={{ href: hit.route }} key={`${hit.objectId}-${index}`} />
          })}
        </div>
      )}
    </div>
  )
}

const SearchCustomHit = connectHits<HitsProps, SuggestionResultProps>(Hits)

export default SearchCustomHit
